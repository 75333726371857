import _ from 'lodash'
import { Dispatch as ReactDispatch } from 'react'
import { PatientReferral, PatientReferralSave } from '../types/PatientReferral'

type ReferralYes = {
  refer: 'Yes'
  phone: string
}

type ReferralNo = {
  refer: 'No'
  reason: string | null
}

export type ReferralDecision = ReferralYes | ReferralNo

export type MaybeReferralDecision = ReferralDecision | null

export function isYes(
  decision: MaybeReferralDecision
): decision is ReferralYes {
  return decision?.refer == 'Yes'
}

export function isNo(decision: MaybeReferralDecision): decision is ReferralNo {
  return decision?.refer == 'No'
}

export const initialState = {
  _type: 'drTinder' as const,
  loading: false,
  referrals: [] as PatientReferral[],
  decisions: {} as { [id: string]: ReferralDecision },
  notes: {} as { [id: string]: string },
  textFilter: '',
  selectionFilters: {
    group: [] as string[],
    practice: [] as string[],
    ipa: [] as string[],
    provider: [] as string[],
    healthPlan: [] as string[],
  },
}
export type State = typeof initialState

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function setLoading(loading: boolean) {
  return {
    type: 'drTinder/setLoading',
    loading,
  } as const
}

export function setReferrals(referrals: PatientReferral[]) {
  return {
    type: 'drTinder/setReferrals',
    referrals,
  } as const
}

export function setReferralsFromSaved(referrals: PatientReferralSave[]) {
  return {
    type: 'drTinder/setReferralsFromSaved',
    referrals,
  } as const
}

export function toggleYes(id: string) {
  return {
    type: 'drTinder/toggleYes',
    id,
  } as const
}

export function toggleNo(id: string) {
  return {
    type: 'drTinder/toggleNo',
    id,
  } as const
}

export function setPhone(id: string, phone: string) {
  return {
    type: 'drTinder/setPhone',
    id,
    phone,
  } as const
}

export function setDeclineReason(id: string, reason: string | null) {
  return {
    type: 'drTinder/setDeclineReason',
    id,
    reason,
  } as const
}

export function setNotes(id: string, notes: string) {
  return {
    type: 'drTinder/setNotes',
    id,
    notes,
  } as const
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */

// type generation easier but exporting individually becomes more dificult
// const actions = {
//   setReferrals: (referrals: PatientReferral[]) => ({ referrals })
// }

type Action = ReturnType<
  | typeof setLoading
  | typeof setReferrals
  | typeof setReferralsFromSaved
  | typeof toggleYes
  | typeof toggleNo
  | typeof setPhone
  | typeof setDeclineReason
  | typeof setNotes
>

export type Dispatch = ReactDispatch<Action>

export function reducer(s: State, a: Action): State {
  switch (a.type) {
    case 'drTinder/setLoading':
      return {
        ...s,
        loading: a.loading,
      }

    case 'drTinder/setReferrals':
      return {
        ...s,
        referrals: a.referrals,
      }

    case 'drTinder/setReferralsFromSaved':
      return {
        ...s,
        referrals: a.referrals,
        decisions: Object.fromEntries<ReferralDecision>(
          a.referrals
            .filter((r) => r.IsReferred != null)
            .map((r) => [
              r.GlobalMemberID,
              r.IsReferred
                ? { refer: 'Yes', phone: r.patient_phone ?? '' } // gross, need to verify
                : { refer: 'No', reason: r.reasonDenied },
            ])
        ),
      }

    case 'drTinder/toggleYes': {
      const existing = s.decisions[a.id]

      if (isYes(existing)) {
        return { ...s, decisions: _.omit(s.decisions, a.id) }
      } else {
        return {
          ...s,
          decisions: {
            ...s.decisions,
            [a.id]: {
              refer: 'Yes',
              phone:
                s.referrals.find((r) => r.GlobalMemberID == a.id)
                  ?.patient_phone || '',
            },
          },
        }
      }
    }

    case 'drTinder/toggleNo': {
      const existing = s.decisions[a.id]

      if (isNo(existing)) {
        return { ...s, decisions: _.omit(s.decisions, a.id) }
      } else {
        return {
          ...s,
          decisions: {
            ...s.decisions,
            [a.id]: {
              refer: 'No',
              reason: null,
            },
          },
        }
      }
    }

    case 'drTinder/setPhone': {
      // if(!s.decisions[a.id]) { return s }

      const decisions = {
        ...s.decisions,
        [a.id]: { refer: 'Yes', phone: a.phone } as ReferralYes,
      }

      return {
        ...s,
        decisions,
      }
    }

    case 'drTinder/setDeclineReason': {
      // if(!s.decisions[a.id]) { return s }

      const decisions = {
        ...s.decisions,
        [a.id]: { refer: 'No', reason: a.reason } as ReferralNo,
      }

      return {
        ...s,
        decisions,
      }
    }

    case 'drTinder/setNotes': {
      const notes = a.notes
        ? { ...s.notes, [a.id]: a.notes }
        : _.omit(s.notes, a.id)
      return { ...s, notes }
    }
  }
}

export type ReferralWithFeedback = PatientReferral & {
  decision: MaybeReferralDecision
  notes: string | null
}

export function withFeedback(state: State): ReferralWithFeedback[] {
  return state.referrals.map((r) => ({
    ...r,
    decision: state.decisions[r.GlobalMemberID],
    notes: state.notes[r.GlobalMemberID],
  }))
}

import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useSearchParams } from '../hooks/useSearchParams'
import { ReferralList, ReferralListResponse } from '../types/ReferralList'
import { RouteComponentProps } from '../types/routes'
import { useApiContext } from './ApiContext'
import AppTitle from './AppTitle'
import CurrentPageTitle from './CurrentPageTitle'
import { SavedReferralListsTable } from './SavedReferralListsTable'

export function SavedReferralLists(
  _props: RouteComponentProps<'saved_referral_lists'>
): JSX.Element {
  const api = useApiContext()
  const [loading, setLoading] = useState(false)
  const [lists, setLists] = useState<ReferralList[]>([])

  const userid = useSearchParams().get('userid')

  useEffect(() => {
    setLoading(true)

    api
      .get<ReferralListResponse[]>('referral_lists', { userid })
      .then((resp) => {
        setLists(
          resp.map((l) => ({ ...l, date_inserted: new Date(l.date_inserted) }))
        )
        setLoading(false)
      })
  }, [])

  return (
    <AppTitle title="Most Recent Lists">
      <Box
        display="flex"
        justifyContent="center"
        marginTop={2}
        marginBottom={2}
      >
        <CurrentPageTitle />
      </Box>
      <SavedReferralListsTable lists={lists} loading={loading} />
    </AppTitle>
  )
}

const orgTypes = ['System', 'Practice', 'IPA', 'Group'] as const
export type OrgType = typeof orgTypes[number]

export type PatientReferral = {
  GlobalMemberID: string // number?
  patient_phone: string | null
  orgName: string
  ipaName: string | null
  orgType: OrgType
  orgId: number
  orgState: string
  ProviderNPI: string
  ProviderID: number
  providerName: string
  providerFirstName: string | null
  providerLastName: string | null
  providerAddress: string | null
  ProviderPhone: string
  practiceName: string | null
  ProviderPracticeID: number // | null
  practiceAddress: string | null
  groupName: string | null
  ProviderGroupID: number
  systemName: string
  ProviderSystemID: number
  hpName: string
  patientFirstName: string | null
  patientLastName: string | null
  patientDob: string
  TotalPaid12m: string
  totaladmissions12m: number
  memberID: string // only used for display on print
}

// NOTE(adam): (ReferralListID: number) is also returned on loading saved but not currently needed
export type PatientReferralSave = PatientReferral & {
  IsReferred: boolean | null
  reasonDenied: string | null
  notes: string | null
}

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function isOrgType(arg: any): arg is OrgType {
  return orgTypes.includes(arg)
}
/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

import { Box } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import { useEffect, useMemo, useState } from 'react'
import { useApiContext } from './ApiContext'
import AppTitle from './AppTitle'
import CurrentPageTitle from './CurrentPageTitle'
import FilterTextBox from './FilterTextBox'
import OpportunitiesTable, { Opportunity } from './OpportunitiesTable'

export default function OpportunitiesPage(
  _props: RouteComponentProps
): JSX.Element {
  const api = useApiContext()

  const [opportunities, setOpportunities] = useState<Opportunity[]>([])
  const [loading, setLoading] = useState(false)

  const [filter, setFilter] = useState<string>('')

  useEffect(() => {
    setLoading(true)
    api
      .get<Opportunity[]>('provider_relations/quick_wins')
      .then(setOpportunities)
      .then((opps) => (setLoading(false), opps))
  }, [])

  const filteredOpps: Opportunity[] = useMemo(
    () =>
      (opportunities || []).filter((o) =>
        o.practice_name.toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, opportunities]
  )

  return (
    <AppTitle title="Opportunities">
      <Box display="flex" flexDirection="column" alignItems="stretch">
        <Box display="flex" justifyContent="center">
          <CurrentPageTitle />
        </Box>
        <Box display="flex" justifyContent="flex-end" margin={1}>
          <FilterTextBox value={filter} onChange={setFilter} />
        </Box>
        <OpportunitiesTable loading={loading} opportunities={filteredOpps} />
      </Box>
    </AppTitle>
  )
}

import { Box } from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridSortDirection,
  GridSortModel,
  GridValueFormatterParams,
} from '@material-ui/data-grid'
import { useMemo } from 'react'
import { ReferralListButton } from './ReferralListButton'

export type Opportunity = {
  id: string
  ProviderPracticeID: string
  practice_name: string
  total_referred_12m: string
  total_list_ready: number
  opp: string
  num: string
  last_90_days: string
  last_60_days: string
  last_30_days: string
  ProviderState?: string
}

const columns: GridColDef[] = [
  { field: 'ProviderPracticeID', hide: true },
  {
    field: 'practice_name',
    headerName: 'Practice',
    flex: 1,
  },
  {
    field: 'total_list_ready',
    headerName: 'List Ready',
    width: 125,
  },
  {
    field: 'total_referred_12m',
    headerName: 'Referred 1 Year',
    width: 175,
  },
  {
    field: 'last_90_days',
    headerName: 'Referred 90 Days',
    width: 175,
  },
  {
    field: 'last_60_days',
    headerName: 'Referred 60 Days',
    width: 175,
  },
  {
    field: 'last_30_days',
    headerName: 'Referred 30 Days',
    width: 175,
  },
  {
    field: 'num',
    headerName: 'Priority',
    width: 100,
  },
  {
    field: 'opp',
    headerName: 'Opportunity',
    width: 150,
  },
  {
    field: 'ProviderState',
    headerName: 'State',
    width: 100,
  },
  {
    field: 'goToOrg',
    headerName: 'Go To',
    width: 100,
    renderCell: function GoTo({ getValue }: GridValueFormatterParams) {
      return (
        <ReferralListButton
          listType="new"
          orgId={getValue('ProviderPracticeID') as string}
          orgName={getValue('practice_name') as string}
        />
      )
    },
  },
]

const sortModel: GridSortModel = [
  {
    field: 'practice_name',
    sort: 'asc' as GridSortDirection,
  },
]

type Props = {
  loading?: boolean
  opportunities: Opportunity[]
}

export default function OpportunitiesTable({
  loading = false,
  opportunities,
}: Props): JSX.Element {
  const rows: GridRowModel[] = useMemo(
    () => opportunities.map((o) => ({ ...o, id: o.ProviderPracticeID })),
    [opportunities]
  )

  return (
    <Box display="flex" height="100%">
      <Box flexGrow={1} minHeight={700}>
        <DataGrid
          loading={loading}
          columns={columns}
          sortModel={sortModel}
          rows={rows}
        />
      </Box>
    </Box>
  )
}

/**
 * Insert commas into numbers with more than 3 digits, trimming any long
 * decimals to 2 digits.
 *
 * @param value - A string or number to be formatted.
 * @returns The formatted string
 *
 * @example
 *
 * ```
 * commafyNumber(1000)
 * // returns '1,000'
 *
 * commafyNumber('1000')
 * // returns '1,000'
 *
 * commafyNumber('1000.0000')
 * // returns '1,000.00'
 * ```
 */
export function commafyNumber(value: string | number): string {
  let num: number

  switch (typeof value) {
    case 'number': {
      num = value
      break
    }
    case 'string': {
      if (value.includes('.')) {
        num = parseFloat(value)
      } else {
        num = parseInt(value)
      }
      break
    }
  }

  return num.toLocaleString().replace(/(\.\d\d)\d+/, '$1')
}

/**
 * Capitalize each word in a string.
 *
 * @param string - A string to recase
 * @returns The recased string
 *
 * @example Recasing multiple words
 *
 * ```
 * capitalize('this is a STRING')
 * // returns 'This Is A String'
 * ```
 */
export function capitalize(string: string): string {
  return string.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())
}

/**
 * Format a 10-digit string as a phone number.
 *
 * @param string - A string to format
 * @returns The string, formatted if it is 10 digits
 *
 * @example Formatting 10 digits
 *
 * ```
 * formatPhone('2345678901')
 * // returns '234-567-8901'
 * ```
 *
 * @example Ignoring non-10 digit string
 *
 * ```
 * formatPhone('foo')
 * // returns 'foo'
 * ```
 */
export function formatPhone(string: string): string {
  return string.match(/\d{10}/)
    ? string.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    : string
}

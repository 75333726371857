import {
  AppBar,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Theme,
  Toolbar,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { useNavigate } from '@reach/router'
import clsx from 'clsx'
import { useState } from 'react'
import { RouteComponentProps } from '../types/routes'
import { useAppTitle } from './AppTitle'
import { useUserContext } from './UserContext'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    hide: {
      display: 'none',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    title: {
      flexGrow: 1,
    },
  })
)

// TODO(adam): reconcile these with the Path types, navigate vs match is difficult
const providerRelationsRoutes: [string, string][] = [
  ['Organizations', '/provider_relations'],
  ['Opportunities', '/provider_relations/opportunities'],
]

const otherRoutes: [string, string][] = [
  ['Markets', '/markets'],
  ['Provider Search', '/provider_search'],
  ['My Saved Lists', '/saved_referral_lists'],
]

function NavDrawer({ open, onClose }: { open: boolean; onClose: () => void }) {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={onClose}>
          <Icon>chevron_left</Icon>
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem disabled>
          <ListItemText primary="Provider Relations" />
        </ListItem>
        {providerRelationsRoutes.map(([label, route]) => (
          <ListItem button onClick={() => navigate(route)} key={route}>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {otherRoutes.map(([label, route]) => (
          <ListItem button onClick={() => navigate(route)} key={route}>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

function DrawerButton({ open, onOpen }: { open: boolean; onOpen: () => void }) {
  const classes = useStyles()

  return (
    <IconButton
      edge="start"
      color="inherit"
      className={clsx(classes.menuButton, open && classes.hide)}
      onClick={onOpen}
    >
      <Icon>menu</Icon>
    </IconButton>
  )
}

function LogOutButton() {
  const { logOut } = useUserContext()

  return (
    <IconButton edge="start" color="inherit" onClick={logOut}>
      <Icon>logout</Icon>
    </IconButton>
  )
}

type OwnProps = {
  children: React.ReactNode
}

type Props = OwnProps & RouteComponentProps<'/'>

export default function Layout({ children }: Props): JSX.Element {
  const classes = useStyles()
  const title = useAppTitle()

  const [open, setOpen] = useState(false)

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <DrawerButton open={open} onOpen={() => setOpen(true)} />
          <Typography className={classes.title}>{title}</Typography>
          <LogOutButton />
        </Toolbar>
      </AppBar>
      <NavDrawer open={open} onClose={() => setOpen(false)} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  )
}

import { Router } from '@reach/router'
import { RouteComponentProps } from '../types/routes'
import AppTitle from './AppTitle'
import OpportunitiesPage from './OpportunitiesPage'
import OrganizationsPage from './OrganizationsPage'

export default function ProviderRelations(
  _props: RouteComponentProps<'provider_relations/*'>
): JSX.Element {
  return (
    <AppTitle title="Provider Relations">
      <Router primary={false}>
        <OrganizationsPage path="/" />
        <OpportunitiesPage path="opportunities" />
      </Router>
    </AppTitle>
  )
}

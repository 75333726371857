import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { DataGrid, GridColDef, GridSortDirection } from '@material-ui/data-grid'
import { useLocation, useNavigate } from '@reach/router'
import { useEffect, useState } from 'react'
import { useSearchParams } from '../hooks/useSearchParams'
import { RouteComponentProps } from '../types/routes'
import { formatPhone } from '../utils/format'
import { useApiContext } from './ApiContext'
import AppTitle from './AppTitle'
import CurrentPageTitle from './CurrentPageTitle'
import DownloadButton from './DownloadButton'
import { Hide } from './Hide'

type PatientInfo = {
  name: string
  dob: string
  phone: string | null
  contract: string
  insuranceNumber: string
}

const useInfoStyles = makeStyles((theme) => ({
  label: {
    paddingRight: theme.spacing(1),
    border: 'none',
  },
  value: {
    border: 'none',
  },
}))

function InfoRow({
  label,
  children,
}: {
  label: string
  children: string | null | undefined
}) {
  const classes = useInfoStyles()

  return (
    <TableRow>
      <TableCell variant="head" classes={{ root: classes.label }}>
        {label}
      </TableCell>
      <TableCell classes={{ root: classes.value }}>{children}</TableCell>
    </TableRow>
  )
}

type InfoProps = {
  patient?: PatientInfo
}

function PatientInfo({ patient }: InfoProps) {
  return (
    <Card>
      <CardContent>
        <Table padding="none">
          <TableBody>
            <InfoRow label="Patient">{patient?.name}</InfoRow>
            <InfoRow label="DOB">{patient?.dob}</InfoRow>
            <InfoRow label="Phone">{patient?.phone}</InfoRow>
            <InfoRow label="Contract">{patient?.contract}</InfoRow>
            <InfoRow label="Ins #">{patient?.insuranceNumber}</InfoRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

function PatientInfoButton({
  infoShown,
  ...props
}: { infoShown?: boolean } & ButtonProps) {
  return (
    <Button
      {...props}
      variant="contained"
      endIcon={
        <Icon>{infoShown ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
      }
    />
  )
}

type SearchProps = {
  initialValue?: string
  onSearch: (value: string) => void
}

const contactQueryParam = 'contact_id'

function PatientSearch({ initialValue, onSearch }: SearchProps) {
  const [searchValue, setSearchValue] = useState(initialValue ?? '')

  const location = useLocation()
  const navigate = useNavigate()

  const submit = (event: React.FormEvent) => {
    navigate(`${location.pathname}?contact_id=${searchValue}`, {
      replace: true,
    })
    onSearch(searchValue)
    event.preventDefault()
  }

  return (
    <form onSubmit={submit}>
      <Box display="flex" alignItems="center">
        <TextField
          name="patient-id"
          label="Patient ID"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            ),
          }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Button type="submit" color="primary" variant="outlined">
          Search
        </Button>
      </Box>
    </form>
  )
}

type ProviderResponse = {
  sfPCP: string
  ProviderID: number
  SalesForceID: string
  ProviderNPI: string
  RoleName: string
  RoleDescription: string
  Confidence: number
  MaxServiceDate: string
  N_claims_per_member: number
  ProviderName: string
  ProviderFirstName: string
  ProviderLastName: string
  ProviderAddress1: string
  ProviderAddress2: string | null
  ProviderCity: string
  ProviderState: string
  ProviderPhone: string
  ProviderPhoneExtension: string | null
  HealthPlanName: string
  PatientStatus: string
  PrimaryContract: string
}

type Provider = {
  id: number
  npi: string
  sfPCP: string
  role: string
  confidence: number
  serviceDate: string
  claims: number
  name: string
  address: string
  city: string
  state: string
  phone: string
  ext: string | null
}

type ResultsTableProps = {
  loading?: boolean
  providers: Provider[]
}

const mapProviderResponse = (prov: ProviderResponse): Provider => ({
  id: prov.ProviderID,
  npi: prov.ProviderNPI,
  sfPCP: prov.sfPCP,
  role: prov.RoleName,
  confidence: prov.Confidence,
  serviceDate: prov.MaxServiceDate,
  claims: prov.N_claims_per_member,
  name: prov.ProviderName,
  address: [prov.ProviderAddress1, prov.ProviderAddress2]
    .filter(Boolean)
    .join(', '),
  city: prov.ProviderCity,
  state: prov.ProviderState,
  phone: prov.ProviderPhone,
  ext: prov.ProviderPhoneExtension,
})

const columns: GridColDef[] = [
  { field: 'sfPCP', headerName: 'SF PCP', width: 100 },
  { field: 'role', headerName: 'Role', width: 180 },
  {
    field: 'confidence',
    headerName: 'Confidence',
    width: 125,
    type: 'number',
  },
  { field: 'serviceDate', headerName: 'Service Date', width: 160 },
  { field: 'claims', headerName: 'Claims', width: 100, type: 'number' },
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'address', headerName: 'Address', flex: 1 },
  { field: 'city', headerName: 'City', width: 150 },
  { field: 'state', headerName: 'State', width: 100 },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 150,
    valueFormatter: ({ value }) => formatPhone(value as string),
  },
  { field: 'ext', headerName: 'Ext', width: 75 },
]

const sortModel = [
  {
    field: 'serviceDate',
    sort: 'desc' as GridSortDirection,
  },
]

function ResultsTable({ loading, providers }: ResultsTableProps) {
  return (
    <Box display="flex" height="100%">
      <Box flexGrow={1} minHeight={800}>
        <DataGrid
          columns={columns}
          rows={providers}
          sortModel={sortModel}
          loading={loading}
        />
      </Box>
    </Box>
  )
}

type SearchResponse = {
  patient: {
    contact_id__pc: string
    npi: string | null
    global_member_id__c: string
    member_id: string
    name: string
    personbirthdate: string
    phone: string | null
    primary_contract__c: string
  }
  providers: Array<ProviderResponse>
}

type FileData = {
  data: ProviderResponse[]
  fileName: string
}

export default function ProviderSearch(
  _props: RouteComponentProps<'provider_search'>
): JSX.Element {
  const api = useApiContext()
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<Provider[]>([])
  const [fileData, setFileData] = useState<FileData | undefined>()
  const [patient, setPatient] = useState<PatientInfo | undefined>()

  const onSearch = (value: string) => {
    setLoading(true)

    api
      .get<SearchResponse>('provider_search', {
        contact_id__pc: value,
      })
      .then(({ patient, providers }) => ({
        patient,
        providers: providers.map((p) => ({
          ...p,

          sfPCP: p.ProviderNPI == patient.npi ? 'Y' : '',
        })),
      }))
      .then((data) => {
        if (!data?.patient) {
          setFileData(undefined)
          setPatient(undefined)
          setResults([])
          return
        }

        setFileData({
          data: data.providers,
          fileName: `${data.patient.contact_id__pc}_providers.csv`,
        })

        setPatient({
          name: data.patient.name,
          dob: data.patient.personbirthdate,
          phone: data.patient.phone,
          contract: data.patient.primary_contract__c,
          insuranceNumber: data.patient.member_id,
        })

        const results = data.providers.map(mapProviderResponse)
        setResults(results)
      })
      .then(() => setLoading(false))
  }

  const contactId = useSearchParams().get(contactQueryParam) ?? ''

  useEffect(() => {
    if (contactId) {
      onSearch(contactId)
    }
  }, [])

  const [showPatientInfo, setShowPatientInfo] = useState(false)

  return (
    <AppTitle title="Provider Search">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <DownloadButton
          disabled={loading || results.length == 0}
          fileName={fileData?.fileName}
          data={fileData?.data}
        />
        <CurrentPageTitle />
        <Box display="flex" alignItems="center">
          <PatientSearch initialValue={contactId} onSearch={onSearch} />
          <Hide visible={patient}>
            <Box marginLeft={2}>
              <PatientInfoButton
                infoShown={patient && showPatientInfo}
                onClick={() => setShowPatientInfo((s) => !s)}
              >
                {patient?.name}
              </PatientInfoButton>
            </Box>
          </Hide>
        </Box>
      </Box>
      <Hide visible={patient && showPatientInfo}>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop={1}
          marginBottom={1}
        >
          <PatientInfo patient={patient} />
        </Box>
      </Hide>
      <ResultsTable loading={loading} providers={results} />
    </AppTitle>
  )
}

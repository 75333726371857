import { CssBaseline } from '@material-ui/core'
import { Redirect, Router } from '@reach/router'
import ReactDOM from 'react-dom'
import { ApiProvider } from './components/ApiContext'
import { AppTitleProvider } from './components/AppTitle'
import Layout from './components/Layout'
import Markets from './components/Markets'
import { NewPatientReviewList } from './components/NewPatientReviewList'
import { PatientReviewList } from './components/PatientReviewList'
import ProviderRelations from './components/ProviderRelations'
import ProviderSearch from './components/ProviderSearch'
import { SavedReferralLists } from './components/SavedReferralLists'
import { UserProvider } from './components/UserContext'

function Orc() {
  return (
    <>
      <CssBaseline />
      <UserProvider>
        <ApiProvider>
          <AppTitleProvider>
            <Router>
              <Layout path="/">
                <Markets path="markets" />
                <ProviderRelations path="provider_relations/*" />
                <ProviderSearch path="provider_search" />
                <NewPatientReviewList path="patient_review_list" />
                <PatientReviewList path="patient_review_list/:listId" />
                <SavedReferralLists path="saved_referral_lists" />
                <Redirect from="/" to="provider_relations" noThrow />
              </Layout>
            </Router>
          </AppTitleProvider>
        </ApiProvider>
      </UserProvider>
    </>
  )
}

ReactDOM.render(<Orc />, document.querySelector('#react-root'))

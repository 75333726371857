import axios, { AxiosInstance, Method } from 'axios'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useUserContext } from './UserContext'

// TODO(adam): need to spend more time on a better request api
/* eslint-disable @typescript-eslint/no-explicit-any */
type Api = {
  get: <D>(url: string, params?: any) => Promise<D>
  post: <D>(url: string, data: any) => Promise<D>
  put: <D>(url: string, data: any) => Promise<D>
  patch: <D>(url: string, data: any) => Promise<D>
  delete_: <D>(url: string) => Promise<D>
}

const generateId = () => Math.random().toString(36).substr(2, 10)

type ApiStatus = {
  pending: string[]
  errors: string[]
}

type StatusContext = {
  status: ApiStatus
  addError: (errorMessage: string) => void
  popError: () => void
}

const initStatus: ApiStatus = {
  pending: [],
  errors: [],
}

const apiContext = createContext<null | Api>(null)
const apiStatusContext = createContext<null | StatusContext>(null)

const instance: AxiosInstance = axios.create({
  baseURL: `${window.env.apiAddress}/api/`,
})

type ProviderProps = {
  children: JSX.Element
}

export function ApiProvider({ children }: ProviderProps): JSX.Element {
  const user = useUserContext()

  const [status, setStatus] = useState(initStatus)
  const trackRequest = useCallback(
    (requestId: string) =>
      setStatus((s) => ({ ...s, pending: [...s.pending, requestId] })),
    [setStatus]
  )
  const untrackRequest = useCallback(
    (requestId: string) =>
      setStatus((s) => ({
        ...s,
        pending: s.pending.filter((id) => id !== requestId),
      })),
    [setStatus]
  )
  const addError = useCallback(
    (errorMessage: string) =>
      setStatus((s) => ({ ...s, errors: [...s.errors, errorMessage] })),
    [setStatus]
  )
  const popError = useCallback(
    () =>
      setStatus((s) => {
        const [, ...errors] = s.errors

        return {
          ...s,
          errors,
        }
      }),
    [setStatus]
  )

  type RequestFn = <D>(
    url: string,
    method: Method,
    data: any,
    params: any
  ) => Promise<D>

  const request = useCallback<RequestFn>(
    function requestFn<D>(
      url: string,
      method: Method,
      data: any,
      params: any
    ): Promise<D> {
      const requestId = generateId()
      trackRequest(requestId)

      return instance
        .request({
          headers: {
            authorization: `Bearer ${user.token}`,
            userid: user.id,
          },
          url,
          method,
          data,
          params,
        })
        .then(({ data: resp }) => {
          untrackRequest(requestId)

          return resp.status === 'success'
            ? Promise.resolve(resp.data)
            : Promise.reject(resp.message)
        })
        .catch((error) => {
          untrackRequest(requestId)
          throw error
        })
    },
    [user]
  )

  // TODO(adam): cancellation for switching pages

  const api = useMemo<Api>(
    () => ({
      get: function <D>(url: string, params?: any): Promise<D> {
        return request<D>(url, 'get', null, params)
      },
      post: function <D>(url: string, data: any): Promise<D> {
        return request<D>(url, 'post', data, null)
      },
      put: function <D>(url: string, data: any): Promise<D> {
        return request<D>(url, 'put', data, null)
      },
      patch: function <D>(url: string, data: any): Promise<D> {
        return request<D>(url, 'patch', data, null)
      },
      delete_: function <D>(url: string): Promise<D> {
        return request<D>(url, 'delete', null, null)
      },
    }),
    [request]
  )
  /* eslint-enable @typescript-eslint/no-explicit-any */

  const statusContext = useMemo(
    () => ({
      status,
      addError,
      popError,
    }),
    [status, addError, popError]
  )

  return (
    <apiContext.Provider value={api}>
      <apiStatusContext.Provider value={statusContext}>
        {children}
      </apiStatusContext.Provider>
    </apiContext.Provider>
  )
}

export const useApiContext = (): Api => {
  const api = useContext(apiContext)

  if (!api) {
    throw 'useApiContext must be used within ApiProvider'
  }

  return api
}

export function useApiStatus(): StatusContext {
  const status = useContext(apiStatusContext)

  if (!status) {
    throw 'useApiStatus must be used within ApiProvider'
  }

  return status
}

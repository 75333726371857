import { Icon, InputAdornment, TextField } from '@material-ui/core'

type Props = {
  value: string
  onChange: (value: string) => void
  label?: string
}

export default function FilterTextBox({
  value,
  onChange,
  label = 'Filter',
}: Props): JSX.Element {
  return (
    <TextField
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon>search</Icon>
          </InputAdornment>
        ),
      }}
    />
  )
}

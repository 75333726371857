import { Box } from '@material-ui/core'
import { useNavigate } from '@reach/router'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from '../hooks/useSearchParams'
import { OrgType, PatientReferral, isOrgType } from '../types/PatientReferral'
import { ReviewType } from '../types/PatientReview'
import { RouteComponentProps } from '../types/routes'
import { useApiContext } from './ApiContext'
import AppTitle from './AppTitle'
import CurrentPageTitle from './CurrentPageTitle'
import { DrTinderFilters, State as FiltersState } from './DrTinderFilters'
import { DrTinderTableViewOnly } from './DrTinderTableViewOnly'
import { SavePatientReviewListDialog } from './SavePatientReviewListDialog'

export type ReferralListCreate = {
  type: ReviewType
  orgId: number
  orgType: OrgType
  listName: string
  patients: PatientReferral[]
}

const filterToField: {
  [field in keyof FiltersState]: keyof PatientReferral
} = {
  group: 'groupName',
  practice: 'practiceName',
  ipa: 'ipaName',
  provider: 'providerName',
  healthPlan: 'hpName',
}

type ReferralSelectionFilter = [keyof PatientReferral, string[]]

function referralMatches(
  referral: PatientReferral,
  filters: ReferralSelectionFilter[]
): boolean {
  return filters.every(
    ([k, v]) => v.length === 0 || v.includes(`${referral[k]}`)
  )
}

function filterReferrals(
  referrals: PatientReferral[],
  filters: FiltersState
): PatientReferral[] {
  const selectionFilters = (Object.entries(filters) as [
    keyof FiltersState,
    string[]
  ][]).map<ReferralSelectionFilter>(([k, v]) => [filterToField[k], v])

  return referrals.filter((r) => referralMatches(r, selectionFilters))
}

export function NewPatientReviewList(
  _props: RouteComponentProps<'patient_review_list'>
): JSX.Element {
  const navigate = useNavigate()
  const api = useApiContext()
  const [loading, setLoading] = useState(false)

  const [referrals, setReferrals] = useState<PatientReferral[]>([])
  const [filters, setFilters] = useState<FiltersState | null>(null)
  const filtered = useMemo(
    () => (filters ? filterReferrals(referrals, filters) : referrals),
    [referrals, filters]
  )

  const searchParams = useSearchParams()

  const orgType = searchParams.get('orgType')
  const orgId = searchParams.get('orgId')
  const orgName = searchParams.get('orgName')

  // TODO(adam): make query param verification more structured
  if (!orgId || !isOrgType(orgType) || !orgName) {
    throw 'missing params'
  }

  useEffect(() => {
    setLoading(true)

    api
      .get<PatientReferral[]>('provider_relations/patients_for_review', {
        orgId,
        orgType,
      })
      .then((referrals) => {
        setReferrals(referrals)
        setLoading(false)
      })
  }, [])

  const create = (name: string, type: ReviewType) => {
    const body: ReferralListCreate = {
      orgId: parseInt(orgId),
      orgType,
      patients: referrals,
      type,
      listName: name,
    }

    setLoading(true)

    return api
      .post<{ ref_list_id: number }>('provider_relations/refer_patient', body)
      .then((data) => navigate(`patient_review_list/${data.ref_list_id}`))
  }

  return (
    <AppTitle title={`${orgName} Referral List`}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        margin={2}
      >
        <SavePatientReviewListDialog onSave={create} origName={orgName} />
        <CurrentPageTitle />
        <span />
      </Box>
      <DrTinderFilters referrals={referrals} onFilterChange={setFilters} />
      <DrTinderTableViewOnly referrals={filtered} loading={loading} />
    </AppTitle>
  )
}

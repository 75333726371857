import {
  Box,
  Button,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowModel,
  GridSortModel,
  GridValueGetterParams,
} from '@material-ui/data-grid'
import { Autocomplete } from '@material-ui/lab'
import { differenceInCalendarYears, parseISO } from 'date-fns'
import { useMemo, useState } from 'react'
import {
  Dispatch,
  MaybeReferralDecision,
  ReferralWithFeedback,
  isNo,
  isYes,
  setNotes,
  setPhone,
  toggleNo,
  toggleYes,
} from '../data/drTinderReferrals'
import { capitalize, commafyNumber, formatPhone } from '../utils/format'

const useDecisionStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      color: theme.palette.getContrastText(green[400]),
      backgroundColor: green[400],
      '&:hover': {
        backgroundColor: green[600],
      },
    },
    red: {
      color: theme.palette.getContrastText(red[400]),
      backgroundColor: red[400],
      '&:hover': {
        backgroundColor: red[600],
      },
    },
  })
)

function YesButton({ getValue }: GridCellParams) {
  const classes = useDecisionStyles()

  const dispatch = getValue('dispatch') as Dispatch
  const decision = getValue('decision') as MaybeReferralDecision
  const id = getValue('id') as string
  const onClick = () => dispatch(toggleYes(id))

  return (
    <Button
      variant="contained"
      disableElevation
      disableRipple
      className={isYes(decision) ? classes.green : ''}
      onClick={onClick}
    >
      Yes
    </Button>
  )
}

function NoButton({ getValue }: GridCellParams) {
  const classes = useDecisionStyles()

  const dispatch = getValue('dispatch') as Dispatch
  const decision = getValue('decision') as MaybeReferralDecision
  const id = getValue('id') as string
  const onClick = () => dispatch(toggleNo(id))

  return (
    <Button
      variant="contained"
      disableElevation
      disableRipple
      className={isNo(decision) ? classes.red : ''}
      onClick={onClick}
    >
      No
    </Button>
  )
}

type DeclineReason = {
  value: string
  label: string
}

const declineReasons: DeclineReason[] = [
  { value: 'Too Healthy', label: 'Too Healthy' },
  { value: 'No Longer Managing', label: 'No Longer Managing' },
  { value: 'Hospice', label: 'Hospice' },
  { value: 'Deceased', label: 'Deceased' },
  { value: 'Long Term Care', label: 'Long Term Care' },
  { value: 'Physician Rejection', label: 'PCP Request' },
]

function PhoneField({
  className,
  origPhone,
  setPhone,
}: {
  className?: string
  origPhone: string
  setPhone: (ph: string) => void
}) {
  const [phone, setFieldPhone] = useState(origPhone)

  return (
    <TextField
      className={className}
      value={phone}
      onChange={(e) => setFieldPhone(e.target.value)}
      onBlur={() => setPhone(phone)}
    />
  )
}

const useCellNestingStyles = makeStyles((theme) => ({
  centering: {
    marginBottom: theme.spacing(-2),
  },
}))

// NOTE(adam): edit phone if Yes, decline reason select if No
function AdditionalInfo({ getValue }: GridCellParams): JSX.Element {
  const classes = useCellNestingStyles()

  const dispatch = getValue('dispatch') as Dispatch
  const decision = getValue('decision') as MaybeReferralDecision
  const id = getValue('id') as string
  const origPhone = getValue('patient_phone') as string
  const origReason = isNo(decision)
    ? declineReasons.find((r) => r.value === decision.reason) ?? null
    : null

  const [declineReason, setDeclineReason] = useState<DeclineReason | null>(
    origReason
  )

  if (isYes(decision)) {
    return (
      <PhoneField
        className={classes.centering}
        origPhone={origPhone}
        setPhone={(ph) => dispatch(setPhone(id, ph))}
      />
    )
  }

  if (isNo(decision)) {
    return (
      <Autocomplete
        className={classes.centering}
        value={declineReason}
        onChange={(_event, value) => setDeclineReason(value)}
        options={declineReasons}
        getOptionLabel={(option) => option.label}
        fullWidth
        renderInput={(params) => (
          <TextField {...params} label="Decline Reason" variant="outlined" />
        )}
      />
    )
  }

  // NOTE(adam): not using null to satisfy data grid renderCell type
  // TODO(adam): use switch here instead?
  return <span />
}

function PhysicianNotes({ getValue }: GridCellParams): JSX.Element {
  const classes = useCellNestingStyles()

  const dispatch = getValue('dispatch') as Dispatch
  const id = getValue('id') as string
  const notes = (getValue('notes') || '') as string

  const [localNotes, setLocalNotes] = useState(notes)

  return (
    <TextField
      className={classes.centering}
      value={localNotes}
      onChange={(e) => setLocalNotes(e.target.value.trim())}
      onBlur={() => dispatch(setNotes(id, localNotes))}
    />
  )
}

function capitalizeValue({ value }: GridValueGetterParams): string | null {
  return value ? capitalize(value as string) : null
}

function calculateAge(date: string) {
  return differenceInCalendarYears(new Date(), parseISO(date))
}

const columns: GridColDef[] = [
  {
    field: 'dispatch',
    hide: true,
  },
  {
    field: 'decision',
    hide: true,
  },
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'yes',
    headerName: 'Yes',
    headerAlign: 'center',
    width: 90,
    renderCell: YesButton,
    disableColumnMenu: true,
  },
  {
    field: 'no',
    headerName: 'No',
    headerAlign: 'center',
    width: 90,
    renderCell: NoButton,
    disableColumnMenu: true,
  },
  {
    field: 'patientFirstName',
    headerName: 'Patient First Name',
    flex: 1,
    valueFormatter: capitalizeValue,
  },
  {
    field: 'patientLastName',
    headerName: 'Patient Last Name',
    flex: 1,
    valueFormatter: capitalizeValue,
  },
  {
    field: 'patientDob',
    headerName: 'Patient DOB',
    width: 140,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 80,
    valueFormatter: ({ getValue }) =>
      calculateAge(getValue('patientDob') as string),
  },
  {
    field: 'patient_phone',
    headerName: 'Patient Phone',
    width: 150,
    valueFormatter: ({ value }) =>
      value ? formatPhone(value as string) : value,
  },
  {
    field: 'TotalPaid12m',
    headerName: '12 Month Cost',
    type: 'number',
    width: 150,
    valueFormatter: ({ value }) =>
      value
        ? `$${commafyNumber(value as ReferralWithFeedback['TotalPaid12m'])}`
        : 'N/A',
  },
  {
    field: 'totaladmissions12m',
    headerName: '12 Month Admissions',
    type: 'number',
    width: 200,
  },
  {
    field: 'providerFirstName',
    headerName: 'Physician First Name',
    flex: 1,
    valueFormatter: capitalizeValue,
  },
  {
    field: 'providerLastName',
    headerName: 'Physician Last Name',
    flex: 1,
    valueFormatter: capitalizeValue,
  },
  {
    field: 'practiceName',
    headerName: 'Practice Name',
    flex: 1,
  },
  {
    field: 'meta',
    headerName: 'Additional Info',
    width: 200,
    renderCell: AdditionalInfo,
    disableColumnMenu: true,
  },
  {
    field: 'notes',
    headerName: 'Physician Notes',
    width: 200,
    renderCell: PhysicianNotes,
    disableColumnMenu: true,
  },
]

const sortModel: GridSortModel = [
  {
    field: 'patientLastName',
    sort: 'asc',
  },
  {
    field: 'patientFirstName',
    sort: 'asc',
  },
]

type Props = {
  referrals: ReferralWithFeedback[]
  loading?: boolean
  dispatch: Dispatch
}

export default function DrTinderTable({
  referrals,
  loading = false,
  dispatch,
}: Props): JSX.Element {
  const rows: GridRowModel[] = useMemo(
    () =>
      referrals.map((r) => ({
        ...r,
        id: r.GlobalMemberID,
        dispatch,
      })),
    [referrals, dispatch]
  )

  return (
    <Box display="flex">
      <Box flexGrow={1} minHeight={700}>
        <DataGrid
          rows={rows}
          columns={columns}
          sortModel={sortModel}
          loading={loading}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  )
}

import { Box } from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridSortModel,
  GridValueFormatterParams,
} from '@material-ui/data-grid'
import { useMemo } from 'react'
import { ReferralList } from '../types/ReferralList'
import { ReferralListButton } from './ReferralListButton'

const columns: GridColDef[] = [
  {
    field: 'list_id',
    headerName: 'Id',
    width: 100,
    hide: true,
  },
  {
    field: 'list_name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'org_type',
    headerName: 'Org Type',
    width: 125,
  },
  {
    field: 'total_members',
    headerName: 'Patients',
    width: 150,
  },
  {
    field: 'referred',
    headerName: 'Referred',
    width: 150,
  },
  {
    field: 'no_response',
    headerName: 'No Response',
    width: 150,
  },
  {
    field: 'denied',
    headerName: 'Denied',
    width: 150,
  },
  {
    field: 'date_inserted',
    headerName: 'Date Created',
    width: 150,
    type: 'date',
  },
  {
    field: 'referral_list',
    headerName: 'Open',
    width: 100,
    renderCell: function ListButton({ getValue }: GridValueFormatterParams) {
      return (
        <ReferralListButton
          listType="existing"
          listId={getValue('list_id') as number}
        />
      )
    },
  },
]

const sortModel: GridSortModel = [
  {
    field: 'list_id',
    sort: 'desc',
  },
]

export function SavedReferralListsTable({
  lists,
  loading,
}: {
  lists: ReferralList[]
  loading: boolean
}): JSX.Element {
  const rows: GridRowModel[] = useMemo(
    () => lists.map((l) => ({ ...l, id: l.list_id })),
    [lists]
  )

  return (
    <Box display="flex" height="100%">
      <Box flexGrow={1} minHeight={700}>
        <DataGrid
          columns={columns}
          loading={loading}
          rows={rows}
          sortModel={sortModel}
        />
      </Box>
    </Box>
  )
}

import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { RouteComponentProps } from '@reach/router'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useApiContext } from './ApiContext'
import AppTitle from './AppTitle'
import CurrentPageTitle from './CurrentPageTitle'
import DownloadButton from './DownloadButton'
import FilterTextBox from './FilterTextBox'
import OrganizationsProviderSearch, {
  Provider as SearchProvider,
} from './OrganizationsProviderSearch'
import OrganizationsTable, { Org } from './OrganizationsTable'

type MarketSelectProps = {
  market: string | null
  onSelect: (market: string | null) => void
}

function MarketSelect({ market, onSelect }: MarketSelectProps) {
  const api = useApiContext()
  const [markets, setMarkets] = useState<string[]>([])

  useEffect(() => {
    api
      .get<string[]>('/provider_relations/markets')
      .then((m) => m.sort())
      .then(setMarkets)
  }, [])

  return (
    <Autocomplete
      value={market}
      onChange={(_, value) => onSelect(value)}
      options={markets}
      renderInput={(params) => (
        <TextField {...params} label="Select Market" variant="outlined" />
      )}
    />
  )
}

type HeaderProps = {
  Download: JSX.Element
  MarketSelect: JSX.Element
  ProviderSearch: JSX.Element
  Filter: JSX.Element
}

function Header({
  Download,
  MarketSelect,
  ProviderSearch,
  Filter,
}: HeaderProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      margin={2}
    >
      <Box flex={1} display="flex">
        {Download}
      </Box>
      <Box flex={1} display="flex" justifyContent="center">
        <Box width={300}>{MarketSelect}</Box>
      </Box>
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>{ProviderSearch}</Box>
        {Filter}
      </Box>
    </Box>
  )
}

export default function OrganizationsPage(
  _props: RouteComponentProps
): JSX.Element {
  const api = useApiContext()

  const [market, setMarket] = useState<string | null>(null)
  const [provider, setProvider] = useState<SearchProvider | null>(null)

  const fileName = useMemo(
    () => `ref_list_${format(new Date(), 'yyyyMMdd')}.csv`,
    []
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [orgs, setOrgs] = useState<Org[]>([])

  useEffect(() => {
    setLoading(true)

    api
      .get<Org[]>('provider_relations', { market, npi: provider?.npi })
      .then(setOrgs)
      .then(() => setLoading(false))
  }, [market, provider])

  const [filterValue, setFilterValue] = useState<string>('')
  const filteredOrgs = useMemo(
    () =>
      orgs.filter((v) =>
        v.name.toLowerCase().includes(filterValue.toLowerCase())
      ),
    [orgs, filterValue]
  )

  return (
    <AppTitle title="Organizations">
      <Box display="flex" flexDirection="column" alignItems="stretch">
        <Box display="flex" justifyContent="center">
          <CurrentPageTitle />
        </Box>
        <Header
          Download={<DownloadButton fileName={fileName} data={filteredOrgs} />}
          MarketSelect={<MarketSelect market={market} onSelect={setMarket} />}
          ProviderSearch={
            <OrganizationsProviderSearch onSelect={setProvider} />
          }
          Filter={
            <FilterTextBox value={filterValue} onChange={setFilterValue} />
          }
        />
        <OrganizationsTable orgs={filteredOrgs} loading={loading} />
      </Box>
    </AppTitle>
  )
}

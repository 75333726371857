import { Box, Icon, InputAdornment, TextField } from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridSortDirection,
} from '@material-ui/data-grid'
import { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from '../types/routes'
import { useApiContext } from './ApiContext'
import AppTitle from './AppTitle'
import CurrentPageTitle from './CurrentPageTitle'

type MarketsResp = {
  markets: {
    market: string
    active: number
    scheduled: number
    referred: number
    research: number
    capacity: string
    campaign_ready: number | null
    pre_enrollment_count: number | null
    enrollment_rate: number
    conversion_rate: number
  }[]
}

type Market = {
  market: string
  active: number
  scheduled: number
  referred: number
  research: number
  capacity: string
  campaign_ready: number
  pre_enrollment_count: number
  enrollment_rate: number | null
  conversion_rate: number | null
}

const columns: GridColDef[] = [
  { field: 'market', headerName: 'Market', flex: 1 },
  { field: 'active', headerName: 'Active', width: 100, type: 'number' },
  { field: 'scheduled', headerName: 'Scheduled', width: 120, type: 'number' },
  { field: 'referred', headerName: 'Referred', width: 110, type: 'number' },
  { field: 'research', headerName: 'Research', width: 115, type: 'number' },
  {
    field: 'capacity',
    headerName: 'Capacity',
    width: 110,
    type: 'number',
    hide: true,
  },
  {
    field: 'campaign_ready',
    headerName: 'Campaign Ready',
    width: 160,
    type: 'number',
  },
  {
    field: 'pre_enrollment_count',
    headerName: 'Pre Enrollment',
    width: 150,
    type: 'number',
  },
  {
    field: 'enrollment_rate',
    headerName: 'Enrollment Rate',
    width: 155,
    type: 'number',
    valueFormatter: ({ value }) => (value ? `${value}%` : 'N/A'),
  },
  {
    field: 'conversion_rate',
    headerName: 'Conversion Rate',
    width: 160,
    type: 'number',
    valueFormatter: ({ value }) => (value ? `${value}%` : 'N/A'),
  },
]

const initTotals = {
  id: '',
  market: '',
  active: 0,
  scheduled: 0,
  referred: 0,
  research: 0,
  capacity: '',
  campaign_ready: 0,
  pre_enrollment_count: 0,
  enrollment_rate: [] as number[],
  conversion_rate: [] as number[],
}

function MarketsTable({
  markets,
  loading,
}: {
  markets: Market[]
  loading?: boolean
}) {
  const totals = useMemo(() => {
    const t: typeof initTotals = markets.reduce(
      (acc, r) => ({
        id: 'totals',
        market: 'Totals:',
        active: acc.active + r.active,
        scheduled: acc.scheduled + r.scheduled,
        referred: acc.referred + r.referred,
        research: acc.research + r.research,
        capacity: '',
        campaign_ready: acc.campaign_ready + r.campaign_ready,
        pre_enrollment_count: acc.pre_enrollment_count + r.pre_enrollment_count,
        enrollment_rate: [
          ...acc.enrollment_rate,
          (r.enrollment_rate ?? 0) * 1.0,
        ],
        conversion_rate: [
          ...acc.conversion_rate,
          (r.conversion_rate ?? 0) * 1.0,
        ],
      }),
      initTotals
    )

    return {
      ...t,
      enrollment_rate:
        t.enrollment_rate.length == 0
          ? 0
          : (
              t.enrollment_rate.reduce((a: number, b: number) => a + b) /
              t.enrollment_rate.length
            ).toFixed(2),
      conversion_rate:
        t.conversion_rate.length == 0
          ? 0
          : (
              t.conversion_rate.reduce((a: number, b: number) => a + b) /
              t.conversion_rate.length
            ).toFixed(2),
    }
  }, [markets])

  const rows: GridRowModel[] = useMemo(
    () => markets.map((m) => ({ ...m, id: m.market })),
    [markets]
  )

  return (
    <Box display="flex" flexDirection="column">
      <Box flexGrow={1} height={700}>
        <DataGrid
          rows={rows}
          columns={columns}
          sortModel={sortModel}
          loading={loading}
          hideFooter
        />
      </Box>
      <Box height={140}>
        <DataGrid rows={[totals]} columns={columns} hideFooter />
      </Box>
    </Box>
  )
}

const sortModel = [
  {
    field: 'market',
    sort: 'asc' as GridSortDirection,
  },
]

export default function Markets(
  _props: RouteComponentProps<'markets'>
): JSX.Element {
  const api = useApiContext()

  const [markets, setMarkets] = useState<Market[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    api
      .get<MarketsResp>('markets')
      .then((resp) =>
        resp.markets.map((m) => ({
          ...m,
          campaign_ready: m.campaign_ready ?? 0,
          pre_enrollment_count: m.pre_enrollment_count ?? 0,
          enrollment_rate: m.enrollment_rate > 0 ? m.enrollment_rate : null,
          conversion_rate: m.conversion_rate > 0 ? m.conversion_rate : null,
        }))
      )
      .then(setMarkets)
      .then((markets) => (setLoading(false), markets))
  }, [])

  const [filter, setFilter] = useState('')

  const filteredMarkets = useMemo(
    () =>
      markets.filter(({ market }) =>
        market.toLowerCase().includes(filter.toLowerCase())
      ),
    [markets, filter]
  )

  return (
    <AppTitle title="Markets">
      <Box display="flex" alignItems="center">
        <Box flex={1}>
          <CurrentPageTitle />
        </Box>
        <Box margin={1}>
          <TextField
            label="Market Filter"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Box>
      </Box>
      <MarketsTable markets={filteredMarkets} loading={loading} />
    </AppTitle>
  )
}

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { useState } from 'react'
import { ReviewType } from '../types/PatientReview'
import { ActionButton } from './ActionButton'

type SaveModalProps = {
  origName: string
  origReviewType?: ReviewType
  onSave: (name: string, type: ReviewType) => Promise<void>
  update?: boolean
}

export function SavePatientReviewListDialog({
  origName,
  origReviewType = 'AppReview',
  onSave,
  update = false,
}: SaveModalProps): JSX.Element {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState(origName)
  const [reviewType, setReviewType] = useState<ReviewType>(origReviewType)

  return (
    <>
      {update ? (
        <Button onClick={() => setOpen(true)} color="primary">
          Update List Info
        </Button>
      ) : (
        <ActionButton icon="list" onClick={() => setOpen(true)}>
          Create List
        </ActionButton>
      )}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={false}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSave(name, reviewType).then(() => setOpen(false))
          }}
        >
          <DialogTitle>
            {update ? 'Update' : 'Create'} Referral List
          </DialogTitle>
          <DialogContent>
            <Box marginBottom={2} minWidth={500}>
              <TextField
                fullWidth
                label="List Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>

            <FormControl>
              <FormLabel component="legend">Review Type</FormLabel>
              <RadioGroup
                value={reviewType}
                onChange={(e) => setReviewType(e.target.value as ReviewType)}
                row
              >
                <FormControlLabel
                  value="AppReview"
                  control={<Radio />}
                  label="App"
                />
                <FormControlLabel
                  value="DownloadReview"
                  control={<Radio />}
                  label="Download"
                />
                <FormControlLabel
                  value="PrintReview"
                  control={<Radio />}
                  label="Print"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="outlined">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

import { Box, CircularProgress, Typography } from '@material-ui/core'
// https://www.keycloak.org/docs/10.0/securing_apps/index.html#_javascript_adapter
import Keycloak from 'keycloak-js'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

type User = {
  id: string
  token: string
  logOut: () => void
}

const context = createContext<User | null>(null)

function LoadingUser() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h3">Loading User Information</Typography>
      <CircularProgress />
    </Box>
  )
}

type ProviderProps = {
  children: JSX.Element
}

export function UserProvider({ children }: ProviderProps): JSX.Element {
  const keycloak = useMemo(() => Keycloak(window.env.keycloak), [])
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    keycloak.init({ onLoad: 'login-required' }).then((isAuthenticated) => {
      if (!isAuthenticated) {
        throw new Error('Unauthenticated')
      }

      if (!keycloak.token) {
        throw new Error('No keycloak token, which should never happen')
      }

      /* eslint-disable @typescript-eslint/no-explicit-any */
      const userId = (keycloak.tokenParsed as any).user_id as string
      /* eslint-enable @typescript-eslint/no-explicit-any */

      if (!userId) {
        throw new Error('No keycloak user id, which should never happen')
      }

      setUser({
        id: userId,
        token: keycloak.token,
        logOut: () => keycloak.logout(),
      })
    })

    if (__DEV__) {
      keycloak.onTokenExpired = () => {
        keycloak.login({ prompt: 'none' })
      }
    } else {
      keycloak.onTokenExpired = () => {
        alert('Session expired due to inactivity. Please log in again')
        keycloak.login({ prompt: 'login' })
      }
    }
  }, [keycloak])

  return (
    <context.Provider value={user}>
      {user ? children : <LoadingUser />}
    </context.Provider>
  )
}

export const useUserContext = (): User => {
  const user = useContext(context)

  if (!user) {
    throw 'useUserContext must be used within an UserContextProvider'
  }

  return user
}

import { Box, Checkbox, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { PatientReferral } from '../types/PatientReferral'

function Filter({
  label,
  options,
  onChange,
}: {
  label: string
  options: string[]
  onChange: (selection: string[]) => void
}) {
  return (
    <Box clone flex="1" minWidth={300} margin={1}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        limitTags={1}
        onChange={(_e, value) => onChange(value)}
        options={options}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox checked={selected} /> {option}
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={label} />
        )}
      />
    </Box>
  )
}

// NOTE(adam): type predicate to filter out null (and undefined)
// from https://stackoverflow.com/a/46700791
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

const initialState = {
  group: [] as string[],
  practice: [] as string[],
  ipa: [] as string[],
  provider: [] as string[],
  healthPlan: [] as string[],
}

export type State = typeof initialState

function setFilter(
  state: State,
  filter: keyof State,
  selections: string[]
): State {
  return {
    ...state,
    [filter]: selections,
  }
}

export function DrTinderFilters({
  referrals,
  onFilterChange,
}: {
  referrals: PatientReferral[]
  onFilterChange: (filters: State) => void
}): JSX.Element {
  const [filters, setFilters] = useState(initialState)

  useEffect(() => {
    onFilterChange(filters)
  }, [filters])

  const filterOptions = useMemo(() => {
    return {
      groups: _.uniq(referrals.map((r) => r.groupName).filter(notEmpty)),
      practices: _.uniq(referrals.map((r) => r.practiceName).filter(notEmpty)),
      ipas: _.uniq(referrals.map((r) => r.ipaName).filter(notEmpty)),
      providers: _.uniq(referrals.map((r) => r.providerName)),
      healthPlans: _.uniq(referrals.map((r) => r.hpName)),
    }
  }, [referrals])

  return (
    <Box display="flex" flexWrap="wrap">
      <Filter
        label="Group"
        options={filterOptions.groups}
        onChange={(v) => setFilters((f) => setFilter(f, 'group', v))}
      />
      <Filter
        label="Practice"
        options={filterOptions.practices}
        onChange={(v) => setFilters((f) => setFilter(f, 'practice', v))}
      />
      <Filter
        label="IPA"
        options={filterOptions.ipas}
        onChange={(v) => setFilters((f) => setFilter(f, 'ipa', v))}
      />
      <Filter
        label="Provider"
        options={filterOptions.providers}
        onChange={(v) => setFilters((f) => setFilter(f, 'provider', v))}
      />
      <Filter
        label="Health Plan"
        options={filterOptions.healthPlans}
        onChange={(v) => setFilters((f) => setFilter(f, 'healthPlan', v))}
      />
    </Box>
  )
}

import { Button, ButtonProps, Icon } from '@material-ui/core'

type ActionButtonProps = {
  icon: string
} & ButtonProps<'a'>

export function ActionButton({
  icon,
  children,
  ...props
}: ActionButtonProps): JSX.Element {
  return (
    <Button
      component="a"
      color="primary"
      variant="outlined"
      endIcon={<Icon>{icon}</Icon>}
      {...props}
    >
      {children}
    </Button>
  )
}

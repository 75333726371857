import { Box, MenuItem, TextField } from '@material-ui/core'
import { format } from 'date-fns'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { ReferralWithFeedback } from '../data/drTinderReferrals'
import fullLogo from '../fullLogo.png'
import { Extends } from '../types/util'
import { ActionButton } from './ActionButton'

const border = {
  black: { border: '1px solid black' },
  red: { border: '2px solid red' },
}

const style = {
  page: {
    padding: '20px',
    background: 'white',
    breakAfter: 'page',
  },
  logo: {
    width: '200px',
    height: '65px',
    padding: '0px',
  },
  information: {
    padding: '0px',
    textAlign: 'center',
    fontSize: '12px',
  },
  contactInfo: {
    fontSize: '10px',
    margin: '5px',
    textAlign: 'right',
    lineHeight: 1,
  },
  table: {
    fontSize: '12px',
    tableLayout: 'fixed',
    breakInside: 'avoid-page',
    borderCollapse: 'collapse',
  },
  columns: {
    height: '20px',
  },
  row: {
    borderBottom: '1px solid black',
  },
  checkBox: {
    height: '30px',
    textAlign: 'center',
    fontSize: '1.25em',
    marginBottom: '10px',
  },
  footer: {
    textAlign: 'center',
  },
  noPadding: {
    padding: '0px',
  },
  info: {
    padding: '6px',
  },
  sigTitle: {
    fontSize: '1.25em',
    marginRight: '5px',
  },
  sigLine: {
    display: 'inline-block',
    paddingTop: '10px',
    border: '0',
    borderBottom: '2px solid black',
    height: '25px',
    marginRight: '30px',
  },
  highlight: {
    backgroundColor: '#FFFF00',
  },
} as const

function Page({
  rows,
  listId,
  prsName,
}: {
  rows: ReferralWithFeedback[]
  listId: number
  prsName: string
}) {
  const date = format(new Date(), 'M-dd-yyyy')

  return (
    <div style={style.page}>
      <table style={style.table}>
        <thead>
          <tr>
            <td colSpan={1}>
              <img width="200px" height="80px" src={fullLogo} />
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={style.noPadding} colSpan={3}>
              <p style={style.contactInfo}>Aspire Health - Eligible Patients</p>
              <p style={style.contactInfo}>{date}</p>
              <p style={style.contactInfo}>24/7 Patient Line: (844) 232-0500</p>
              <p style={style.contactInfo}>
                <span style={style.highlight}>
                  Referral Fax: (844) 249-5579
                </span>
              </p>
              <p style={style.contactInfo}>
                Referral Email: referrals@aspirehealthcare.com
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={8} style={{ ...border.red, ...style.information }}>
              <p style={style.info}>
                Aspire Health partners with providers and health plans to
                provide supportive care to patients and families living with
                serious illness or multiple complex conditions. Below is a list
                of members who may be appropriate to receive an additional layer
                of support through Aspire.
                <br />
                <b>
                  If you would like to refer a patient to Aspire, please simply
                  circle &quot;Yes&quot; in the column labeled &quot;Refer to
                  Aspire&quot; along with any notes you may have and return the
                  list to Aspire by email (referrals@aspirehealthcare.com). For
                  questions or additional information, please contact us by
                  email (referrals@aspirehealthcare.com) or by phone (844)
                  232-0500.
                </b>
              </p>
            </td>
          </tr>
          <tr style={style.row}>
            <td>Refer to Aspire</td>
            <td>Physician</td>
            <td>Patient</td>
            <td>Patient Phone</td>
            <td>Patient DOB</td>
            <td>Practice Name</td>
            <td>Practice Address</td>
            <td>MemberID</td>
          </tr>
        </thead>
        <tbody>
          {rows.map((r) => (
            <tr key={r.memberID} style={style.row}>
              <td style={{ width: '50px' }}>
                <div
                  style={{
                    ...border.black,
                    ...style.checkBox,
                    ...style.highlight,
                  }}
                >
                  Yes / No
                </div>
                Notes:
              </td>
              <td style={{ width: '150 px', verticalAlign: 'top' }}>
                {r.providerFirstName
                  ?.toLowerCase()
                  ?.replace(/\b\w/g, (l) => l.toUpperCase()) ?? null}{' '}
                {r.providerLastName
                  ?.toLowerCase()
                  ?.replace(/\b\w/g, (l) => l.toUpperCase()) ?? null}
              </td>
              <td style={{ width: '150 px', verticalAlign: 'top' }}>
                {r.patientFirstName
                  ?.toLowerCase()
                  ?.replace(/\b\w/g, (l) => l.toUpperCase()) ?? null}{' '}
                {r.patientLastName
                  ?.toLowerCase()
                  ?.replace(/\b\w/g, (l) => l.toUpperCase()) ?? null}
              </td>
              <td style={{ width: '50 px', verticalAlign: 'top' }}>
                {r.patient_phone ?? ''}
              </td>
              <td style={{ width: '50 px', verticalAlign: 'top' }}>
                {r.patientDob ?? ''}
              </td>
              <td style={{ width: '50 px', verticalAlign: 'top' }}>
                {r.practiceName ?? ''}
              </td>
              <td style={{ width: '50 px', verticalAlign: 'top' }}>
                {r.practiceAddress ?? ''}
              </td>
              <td style={{ width: '50 px', verticalAlign: 'top' }}>
                {r.memberID ?? ''}
              </td>
            </tr>
          ))}

          <tr>
            <td colSpan={8}>
              <div style={{ display: 'flex' }}>
                <span style={style.sigTitle}>Signature:</span>
                <div
                  style={{ ...style.sigLine, ...style.highlight, flex: '3' }}
                />
                <span style={style.sigTitle}>Title:</span>
                <div
                  style={{ ...style.sigLine, ...style.highlight, flex: '2' }}
                />
                <span style={style.sigTitle}>Date:</span>
                <div
                  style={{
                    ...style.sigLine,
                    ...style.highlight,
                    flex: '1',
                    marginRight: '0px',
                  }}
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td style={style.footer} colSpan={8}>
              Privileged and Confidential / List ID : {listId} / PRS Name :{' '}
              {prsName}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

function fullName(ref: ReferralWithFeedback) {
  return `${ref.providerFirstName} ${ref.providerLastName}`
}

export type PrintProps = {
  listId: number
  rows: ReferralWithFeedback[]
  prsName: string
  groupBy?: PrintKey | ''
  orderBy?: PrintKey | ''
}

function PrintTemplate({
  listId,
  rows,
  prsName,
  groupBy,
  orderBy,
}: PrintProps): JSX.Element {
  const grouped = Object.values(_.groupBy(rows, groupBy))

  return (
    <>
      {grouped.map((g, index) => (
        <Page
          key={index}
          rows={_.sortBy(g, orderBy || fullName)}
          listId={listId}
          prsName={prsName}
        />
      ))}
    </>
  )
}

export function PrintWindow({
  data,
}: {
  data: PrintProps | null
}): JSX.Element | null {
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!data) {
      return
    }

    const containerEl = document.createElement('div')
    const externalWindow = window.open('', '_blank', 'noopener,noreferrer')

    if (!externalWindow) {
      throw new Error('Could not create new window')
    }

    externalWindow.document.body.appendChild(containerEl)

    setContainerEl(containerEl)

    // probably don't need cleanup, but it shouldn't hurt
    return () => externalWindow.close()
  }, [data])

  return containerEl && data
    ? createPortal(<PrintTemplate {...data} />, containerEl)
    : null
}

const printFields = [
  {
    key: 'groupName',
    label: 'Group',
  },
  {
    key: 'practiceName',
    label: 'Practice',
  },
  {
    key: 'ipaName',
    label: 'IPA',
  },
  {
    key: 'providerName',
    label: 'Provider',
  },
  {
    key: 'hpName',
    label: 'Health Plan',
  },
] as const

type PrintKey = Extends<
  keyof ReferralWithFeedback,
  typeof printFields[number]['key']
>

type PrintButtonProps = Pick<PrintProps, 'listId' | 'rows' | 'prsName'>

export function PrintButton({
  listId,
  rows,
  prsName,
}: PrintButtonProps): JSX.Element {
  const [printData, setPrintData] = useState<null | PrintProps>(null)
  const [orderBy, setOrderBy] = useState<'' | PrintKey>('')
  const [groupBy, setGroupBy] = useState<'' | PrintKey>('')

  return (
    <Box display="flex" alignItems="center">
      <Box marginRight={2}>
        <ActionButton
          icon="print"
          onClick={() =>
            setPrintData({ listId, rows, prsName, groupBy, orderBy })
          }
        >
          Print
        </ActionButton>
      </Box>

      <Box marginRight={2} minWidth={150}>
        <TextField
          fullWidth
          select
          onChange={(e) => setGroupBy(e.target.value as '' | PrintKey)}
          value={groupBy}
          label="GroupBy Field"
        >
          <MenuItem value="">
            <em>-- None --</em>
          </MenuItem>
          {printFields.map((f) => (
            <MenuItem key={f.key} value={f.key}>
              {f.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box marginRight={2} minWidth={150}>
        <TextField
          fullWidth
          select
          onChange={(e) => setOrderBy(e.target.value as '' | PrintKey)}
          value={orderBy}
          label="OrderBy Field"
        >
          <MenuItem value="">
            <em>-- None --</em>
          </MenuItem>
          {printFields.map((f) => (
            <MenuItem key={f.key} value={f.key}>
              {f.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <PrintWindow data={printData} />
    </Box>
  )
}

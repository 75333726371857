import { createContext, useContext, useEffect, useMemo, useState } from 'react'

type Setter = (title: string[]) => void

const valueContext = createContext<string[] | null>(null)
const setterContext = createContext<Setter | null>(null)

type ProviderProps = {
  children: JSX.Element
}

export function AppTitleProvider({ children }: ProviderProps): JSX.Element {
  const [title, setTitle] = useState<string[]>(['No title set'])

  return (
    <setterContext.Provider value={setTitle}>
      <valueContext.Provider value={title}>{children}</valueContext.Provider>
    </setterContext.Provider>
  )
}

export const useRawAppTitle = (): string[] => {
  const title = useContext(valueContext)

  if (title === null) {
    throw 'useRawAppTitle must be used within an AppTitleProvider'
  }

  return title
}

export const useAppTitle = (): string => {
  const title = useContext(valueContext)

  if (title === null) {
    throw 'useAppTitle must be used within an AppTitleProvider'
  }

  return title.join(' ')
}

type SectionContext = {
  title: string[]
  registerWithParent: () => void
  unregisterFromParent: () => void
}

const sectionContext = createContext<SectionContext>({
  title: [],
  registerWithParent: () => undefined,
  unregisterFromParent: () => undefined,
})

type Props = {
  title: string
  prefixSeparator?: string
  children?: React.ReactNode
}

export default function AppTitle({
  title,
  prefixSeparator = '/',
  children,
}: Props): JSX.Element {
  const setFullAppTitle = useContext(setterContext)

  if (!setFullAppTitle) {
    throw 'AppTitle must be used within an AppTitleProvider'
  }

  const {
    title: parentTitle,
    registerWithParent,
    unregisterFromParent,
  } = useContext(sectionContext)

  useEffect(() => {
    registerWithParent()
    return unregisterFromParent
  }, [])

  const newTitle = useMemo(
    () => [
      ...parentTitle,
      ...(parentTitle.length ? [prefixSeparator] : []),
      title,
    ],
    [parentTitle, prefixSeparator, title]
  )

  const [setByChild, setSetByChild] = useState(false)

  const fullTitle = useAppTitle()

  useEffect(() => {
    if (!setByChild) {
      setFullAppTitle(newTitle)
    }
  }, [setByChild, newTitle, fullTitle])

  const value = useMemo(
    () => ({
      title: newTitle,
      registerWithParent: () => setSetByChild(true),
      unregisterFromParent: () => setSetByChild(false),
    }),
    [newTitle, setSetByChild]
  )

  return (
    <sectionContext.Provider value={value}>{children}</sectionContext.Provider>
  )
}

import { Icon, IconButton } from '@material-ui/core'
import { useNavigate } from '@reach/router'
import { OrgType } from '../types/PatientReferral'

type NewListProps = {
  listType: 'new'
  orgId: string
  orgName: string
  orgType?: OrgType
}

type ExistingListProps = {
  listType: 'existing'
  listId: number
}

type ReferralListButtonProps = NewListProps | ExistingListProps

export function ReferralListButton(
  props: ReferralListButtonProps
): JSX.Element {
  const navigate = useNavigate()

  const route =
    props.listType === 'new'
      ? `/patient_review_list?orgType=${props.orgType ?? 'Practice'}&orgId=${
          props.orgId
        }&orgName=${props.orgName}`
      : `/patient_review_list/${props.listId}`

  return (
    <IconButton onClick={() => navigate(route)} color="secondary">
      <Icon>list</Icon>
    </IconButton>
  )
}

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridSortDirection,
  GridSortModel,
} from '@material-ui/data-grid'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useState } from 'react'
import { useApiContext } from './ApiContext'

export type Provider = Readonly<{
  id: string
  npi: string
  firstName: string
  lastName: string
}>

type State = Readonly<{
  dialogOpen: boolean
  selectedProvider: Provider | null
}>

function initialState(provider: Provider | null): State {
  return {
    dialogOpen: false,
    selectedProvider: provider,
  }
}

function open() {
  return {
    type: 'OPEN',
  } as const
}

function cancel() {
  return {
    type: 'CANCEL',
  } as const
}

function selectProvider(selectedProvider: Provider) {
  return {
    type: 'SELECT_PROVIDER',
    selectedProvider,
  } as const
}

function clear() {
  return {
    type: 'CLEAR',
  } as const
}

type Action = ReturnType<
  typeof open | typeof cancel | typeof selectProvider | typeof clear
>

type Dispatch = React.Dispatch<Action>

function reducer(s: State, a: Action): State {
  switch (a.type) {
    case 'OPEN':
      return { ...s, dialogOpen: true }
    case 'CANCEL':
      return { ...s, dialogOpen: false }
    case 'SELECT_PROVIDER':
      return { ...s, selectedProvider: a.selectedProvider, dialogOpen: false }
    case 'CLEAR':
      return { ...s, selectedProvider: null }
  }
}

function SearchButton({ onClick }: { onClick: () => void }) {
  return (
    <Button color="primary" variant="outlined" onClick={onClick}>
      Search Providers
    </Button>
  )
}

function SelectedChip({
  provider,
  onDelete,
}: {
  provider: Provider
  onDelete: () => void
}) {
  return (
    <Chip
      label={`${provider.firstName} ${provider.lastName}`}
      onDelete={onDelete}
    />
  )
}

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First Name', width: 300 },
  { field: 'lastName', headerName: 'Last Name', width: 300 },
  { field: 'npi', headerName: 'NPI', width: 150 },
]

const sortModel: GridSortModel = [
  {
    field: 'lastName',
    sort: 'asc' as GridSortDirection,
  },
  {
    field: 'firstName',
    sort: 'asc' as GridSortDirection,
  },
]

function SearchDialog({
  open,
  dispatch,
}: {
  open: boolean
  dispatch: Dispatch
}) {
  const api = useApiContext()

  const [searchTerms, setSearchTerms] = useState<Omit<Provider, 'id'>>({
    firstName: '',
    lastName: '',
    npi: '',
  })

  const [rows, setRows] = useState<Provider[]>([])
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const selection = useMemo(
    () => rows.find((r) => selectedId && r.id === selectedId),
    [rows, selectedId]
  )

  const search = () => {
    setLoading(true)

    api
      .get<
        {
          providerName: string
          providerLastName: string
          providerFirstName: string
          providerNPI: string
        }[]
      >(
        'provider_relations/providers',
        _.chain(searchTerms)
          .toPairs()
          .filter(([_, v]) => Boolean(v))
          .fromPairs()
          .value()
      )
      .then((r) =>
        r.map<Provider>((p) => ({
          id: p.providerNPI,
          npi: p.providerNPI,
          firstName: p.providerFirstName,
          lastName: p.providerLastName,
        }))
      )
      .then((rows) => {
        setRows(rows)
        setLoading(false)
      })
  }

  const selectionLabel = useMemo(() => {
    if (!selection) {
      return ''
    }

    return `Selected: ${selection.firstName} ${selection.lastName}, NPI: ${selection.npi}`
  }, [selection])

  return (
    <Dialog open={open} onClose={() => dispatch(cancel())} maxWidth={false}>
      <DialogTitle>Search Providers</DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            search()
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={2}
          >
            <Box flex={1} display="flex" marginRight={2}>
              <TextField
                label="Provider First Name"
                value={searchTerms.firstName}
                onChange={(e) => {
                  const value = e.target.value
                  setSearchTerms((s) => ({ ...s, firstName: value }))
                }}
                fullWidth
                variant="filled"
              />
            </Box>
            <Box flex={1} display="flex" marginRight={2}>
              <TextField
                label="Provider Last Name"
                value={searchTerms.lastName}
                onChange={(e) => {
                  const value = e.target.value
                  setSearchTerms((s) => ({ ...s, lastName: value }))
                }}
                fullWidth
                variant="filled"
              />
            </Box>
            <Box flex={1} display="flex" marginRight={2}>
              <TextField
                label="Provider NPI"
                value={searchTerms.npi}
                onChange={(e) => {
                  const value = e.target.value
                  setSearchTerms((s) => ({ ...s, npi: value }))
                }}
                fullWidth
                variant="filled"
              />
            </Box>
            <Button color="primary" variant="contained" type="submit">
              Search
            </Button>
          </Box>
        </form>
        <Box height={700} width={800}>
          <DataGrid
            columns={columns}
            rows={rows}
            loading={loading}
            sortModel={sortModel}
            disableMultipleSelection
            onSelectionModelChange={({ selectionModel: [selectedId] }) =>
              setSelectedId(
                // NOTE(adam): this should be a known type which is not a number
                typeof selectedId === 'number'
                  ? selectedId.toString()
                  : selectedId
              )
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box marginLeft={3}>
            <Typography>{selectionLabel}</Typography>
          </Box>
          <Box>
            <Button color="primary" onClick={() => dispatch(cancel())}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!selection}
              onClick={() => selection && dispatch(selectProvider(selection))}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

type Props = {
  provider?: Provider
  onSelect: (provider: Provider | null) => void
}

export default function OrganizationsProviderSearch({
  provider,
  onSelect,
}: Props): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState(provider || null))

  useEffect(() => {
    onSelect(state.selectedProvider)
  }, [state.selectedProvider])

  return (
    <>
      <SearchButton onClick={() => dispatch(open())} />
      {state.selectedProvider && (
        <SelectedChip
          provider={state.selectedProvider}
          onDelete={() => dispatch(clear())}
        />
      )}
      <SearchDialog open={state.dialogOpen} dispatch={dispatch} />
    </>
  )
}

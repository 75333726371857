import { Box } from '@material-ui/core'
import { format } from 'date-fns'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useState } from 'react'
import {
  ReferralWithFeedback,
  initialState,
  reducer,
  setLoading,
  setReferralsFromSaved,
  withFeedback,
} from '../data/drTinderReferrals'
import { PatientReferralSave } from '../types/PatientReferral'
import { ReviewType } from '../types/PatientReview'
import { ReferralListResponse } from '../types/ReferralList'
import { RouteComponentProps } from '../types/routes'
import { ActionButton } from './ActionButton'
import { useApiContext } from './ApiContext'
import AppTitle from './AppTitle'
import CurrentPageTitle from './CurrentPageTitle'
import DownloadButton from './DownloadButton'
import DrTinderTable from './DrTinderTable'
import { DrTinderTableViewOnly } from './DrTinderTableViewOnly'
import { PrintButton } from './PrintTemplate'
import { SavePatientReviewListDialog } from './SavePatientReviewListDialog'

const downloadFields = [
  'orgName',
  'groupName',
  'practiceName',
  'providerLastName',
  'providerFirstName',
  'patientLastName',
  'patientFirstName',
  'patientDob',
  'patient_phone',
  'hpName',
  'memberID',
  'GlobalMemberID',
] as const

type DownloadField = typeof downloadFields[number]

type DownloadEntry = { [k in DownloadField]: ReferralWithFeedback[k] }

function Download({ rows }: { rows: ReferralWithFeedback[] }) {
  const fileName = useMemo(
    () => `ref_list_${format(new Date(), 'yyyyMMdd')}.csv`,
    []
  )

  const data: DownloadEntry[] = useMemo(
    () => rows.map((r) => _.pick(r, downloadFields)),
    [rows]
  )

  return <DownloadButton fileName={fileName} data={data} />
}

function Print({
  rows,
  listId,
  prsName,
}: {
  rows: ReferralWithFeedback[]
  listId: number
  prsName: string
}) {
  return <PrintButton rows={rows} listId={listId} prsName={prsName} />
}

function SavePatients({
  rows,
  listId,
}: {
  rows: ReferralWithFeedback[]
  listId: number
}) {
  const [saving, setSaving] = useState(false)

  const api = useApiContext()
  const save = () => {
    const body = {
      list_id: listId,
      patients: rows,
    }

    setSaving(true)

    api
      .post(`provider_relations/update_patients_for_review`, body)
      .then(() => setSaving(false))
  }

  return (
    <ActionButton icon="save" onClick={save} disabled={saving}>
      {saving ? 'Saving' : 'Save'}
    </ActionButton>
  )
}

function ReviewOptions({
  type,
  listId,
  rows,
  prsName,
}: {
  type: ReviewType
  rows: ReferralWithFeedback[]
  listId: number
  prsName: string
}): JSX.Element | null {
  switch (type) {
    case 'AppReview':
      return <SavePatients listId={listId} rows={rows} />
    case 'PrintReview':
      return <Print listId={listId} rows={rows} prsName={prsName} />
    case 'DownloadReview':
      return <Download rows={rows} />
  }
}

type ReferralListUpdate = {
  list_id: number
  type: ReviewType
  list_name: string
}

type ReviewProps = RouteComponentProps<'patient_review_list/:listId'>

export function PatientReviewList({
  listId: listIdStr,
}: ReviewProps): JSX.Element | null {
  // NOTE(adam): the listId param is required by the route but can't type it that way
  const listId = parseInt(listIdStr ?? '')

  const api = useApiContext()

  const [state, dispatch] = useReducer(reducer, initialState)
  const [listData, setListData] = useState<null | ReferralListResponse>(null)
  const filtered = withFeedback(state)

  useEffect(() => {
    dispatch(setLoading(true))

    api
      .get<ReferralListResponse[]>('referral_lists', {
        list_id: listId,
      })
      .then((resp) => {
        const data = resp[0]

        if (!data) {
          throw new Error(`No list data found for list id: ${listId}`)
        } else {
          setListData(data)
        }
      })
      .then(() =>
        api.get<PatientReferralSave[]>(
          'provider_relations/patients_for_review',
          {
            listId,
          }
        )
      )
      .then((referrals) => {
        dispatch(setReferralsFromSaved(referrals))
        dispatch(setLoading(false))
      })
  }, [])

  const update = (name: string, type: ReviewType) => {
    const body: { referralLists: ReferralListUpdate[] } = {
      referralLists: [
        {
          type,
          list_id: listId,
          list_name: name,
        },
      ],
    }

    return api.post('provider_relations/update_referral_list', body).then(() =>
      setListData((l) => {
        if (!l) throw new Error('missing referral list in update')

        return { ...l, list_name: name, type }
      })
    )
  }

  return (
    <AppTitle title={`${listData?.list_name} Referral List`}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        margin={2}
      >
        <Box>
          {listData && (
            <ReviewOptions
              type={listData.type}
              rows={filtered}
              listId={listId}
              prsName={listData.prs_name}
            />
          )}
        </Box>
        <CurrentPageTitle />
        <Box>
          {listData && (
            <SavePatientReviewListDialog
              onSave={update}
              origName={listData.list_name}
              origReviewType={listData.type}
              update
            />
          )}
        </Box>
      </Box>
      {listData?.type === 'AppReview' ? (
        <DrTinderTable
          referrals={filtered}
          loading={state.loading}
          dispatch={dispatch}
        />
      ) : (
        <DrTinderTableViewOnly referrals={filtered} loading={state.loading} />
      )}
    </AppTitle>
  )
}

import { unparse } from 'papaparse'
import { useMemo } from 'react'
import { ActionButton } from './ActionButton'

/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */
type Unparseable = object[] // | any[][]
/* eslint-enable */

type Props = {
  disabled?: boolean
  fileName?: string
  data?: Unparseable
}

export default function DownloadButton({
  disabled = false,
  fileName,
  data,
}: Props): JSX.Element {
  const disabled_ = disabled || !data

  const href = useMemo(() => {
    if (!data) return ''

    const blob = new Blob([unparse(data)])
    return URL.createObjectURL(blob)
  }, [data])

  return (
    <ActionButton
      disabled={disabled_}
      icon="file_download"
      href={href}
      download={fileName}
      type="text/csv"
    >
      Download
    </ActionButton>
  )
}

import { Box } from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@material-ui/data-grid'
import { useMemo } from 'react'
import { OrgType } from '../types/PatientReferral'
import { commafyNumber } from '../utils/format'
import { ReferralListButton } from './ReferralListButton'

export type Org = {
  orgId: number
  active: number
  attributed_members: number
  discharged: number
  identified: number
  identified_all_time: number
  name: string
  providers: number
  referred: number
  state: string
  type: OrgType
}

// TODO(adam): find a more strict type way to handle ValueGetterParams since we know the type?
const commafy = ({ value }: GridValueGetterParams) =>
  commafyNumber(value as number)

const referralSystemListColumns: GridColDef[] = [
  { field: 'orgId', hide: true },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    valueGetter: ({ value }) => `${value}`.toUpperCase(),
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 100,
  },
  {
    field: 'attributed_members',
    headerName: 'Attributed Patients',
    width: 175,
    type: 'number',
    valueGetter: commafy,
  },
  {
    field: 'providers',
    headerName: 'Attributed Physicians',
    width: 200,
    type: 'number',
    valueGetter: commafy,
  },
  {
    field: 'identified',
    headerName: 'Patients for Review',
    width: 200,
    type: 'number',
    valueGetter: commafy,
  },
  {
    field: 'referred',
    headerName: 'Patients Referred',
    width: 175,
    type: 'number',
    valueGetter: commafy,
  },
  {
    field: 'active',
    headerName: 'Patients Active',
    width: 175,
    type: 'number',
    valueGetter: commafy,
  },
  {
    field: 'discharged',
    headerName: 'Patients Discharged',
    width: 200,
    type: 'number',
    valueGetter: commafy,
  },
  {
    field: 'referral_list',
    headerName: 'Go To',
    width: 100,
    renderCell: function ListButton({ getValue }: GridValueFormatterParams) {
      return (
        <ReferralListButton
          listType="new"
          orgId={`${getValue('orgId')}`}
          orgName={`${getValue('name')}`}
          orgType={getValue('type') as OrgType}
        />
      )
    },
  },
]

const sortModel: GridSortModel = [
  {
    field: 'name',
    sort: 'asc',
  },
]

type Props = {
  orgs: Org[]
  loading?: boolean
}

export default function ReferralSystemListTable({
  orgs,
  loading = false,
}: Props): JSX.Element {
  const rows: GridRowModel[] = useMemo(
    () => orgs.map((r) => ({ ...r, id: r.orgId })),
    [orgs]
  )

  return (
    <Box display="flex" height="100%">
      <Box flexGrow={1} minHeight={700}>
        <DataGrid
          loading={loading}
          columns={referralSystemListColumns}
          rows={rows}
          sortModel={sortModel}
        />
      </Box>
    </Box>
  )
}

import { Box } from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridSortModel,
  GridValueGetterParams,
} from '@material-ui/data-grid'
import { differenceInCalendarYears, parseISO } from 'date-fns'
import { useMemo } from 'react'
import { PatientReferral } from '../types/PatientReferral'
import { capitalize, commafyNumber, formatPhone } from '../utils/format'

function calculateAge(date: string) {
  return differenceInCalendarYears(new Date(), parseISO(date))
}

function capitalizeValue({ value }: GridValueGetterParams): string | null {
  return value ? capitalize(value as string) : null
}

const columns: GridColDef[] = [
  {
    field: 'dispatch',
    hide: true,
  },
  {
    field: 'decision',
    hide: true,
  },
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'patientFirstName',
    headerName: 'Patient First Name',
    flex: 1,
    valueFormatter: capitalizeValue,
  },
  {
    field: 'patientLastName',
    headerName: 'Patient Last Name',
    flex: 1,
    valueFormatter: capitalizeValue,
  },
  {
    field: 'patientDob',
    headerName: 'Patient DOB',
    type: 'date',
    width: 140,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 80,
    valueFormatter: ({ getValue }) =>
      calculateAge(getValue('patientDob') as string),
  },
  {
    field: 'patient_phone',
    headerName: 'Patient Phone',
    width: 150,
    valueFormatter: ({ value }) =>
      value ? formatPhone(value as string) : value,
  },
  {
    field: 'TotalPaid12m',
    headerName: '12 Month Cost',
    type: 'number',
    width: 150,
    valueFormatter: ({ value }) =>
      value
        ? `$${commafyNumber(value as PatientReferral['TotalPaid12m'])}`
        : 'N/A',
  },
  {
    field: 'totaladmissions12m',
    headerName: '12 Month Admissions',
    type: 'number',
    width: 200,
  },
  {
    field: 'providerFirstName',
    headerName: 'Physician First Name',
    flex: 1,
    valueFormatter: capitalizeValue,
  },
  {
    field: 'providerLastName',
    headerName: 'Physician Last Name',
    flex: 1,
    valueFormatter: capitalizeValue,
  },
  {
    field: 'practiceName',
    headerName: 'Practice Name',
    flex: 1,
  },
]

const sortModel: GridSortModel = [
  {
    field: 'patientLastName',
    sort: 'asc',
  },
  {
    field: 'patientFirstName',
    sort: 'asc',
  },
]

type Props = {
  referrals: PatientReferral[]
  loading?: boolean
}

export function DrTinderTableViewOnly({
  referrals,
  loading = false,
}: Props): JSX.Element {
  const rows: GridRowModel[] = useMemo(
    () =>
      referrals.map((r) => ({
        ...r,
        id: r.GlobalMemberID,
      })),
    [referrals]
  )

  return (
    <Box display="flex">
      <Box flexGrow={1} minHeight={700}>
        <DataGrid
          rows={rows}
          columns={columns}
          sortModel={sortModel}
          loading={loading}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  )
}
